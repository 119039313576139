@import "normalize.css";
@import "@fontsource/bellota-text";
@import "@fontsource/sorts-mill-goudy";

html {
  font-family: "Bellota Text", sans-serif;
  font-family: "Sorts Mill Goudy", serif;
}

@supports (font-variation-settings: normal) {
  html {
    font-family: "Bellota Text", sans-serif;
    font-family: "Sorts Mill Goudy", serif;
    // font-feature-settings: ;
  }
}

body,
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
figure,
p,
pre {
  margin: 0;
}

p,
h6 {
  font-family: "Bellota Text";
  line-height: 1.6;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Sorts Mill Goudy", serif;
  font-size: inherit;
  font-weight: inherit;
}

a,
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: inherit;
}

button {
  padding: 0;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
}

html {
  height: 100%;
  box-sizing: border-box;
}

body,
#root {
  height: 100%;
  overflow: hidden;
}

#root {
  font-size: 0.875rem;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

div {
  display: inherit;
}
